@import "ui/styles/mixins";
.menu {
  position: absolute;
  z-index: var(--zIndexDropdown);
  background-color: var(--uiOverlayBackground);
  display: flex;
  flex-direction: column;
  color: var(--textPrimary);
  width: 280px;
  padding: 0;
  border-radius: 4px;
  will-change: filter;
  filter: drop-shadow(0px 0px var(--size-2) var(--shadowColor));
  cursor: default;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &[data-popper-placement^='top'] > .arrow {
    bottom: -10px;
  }

  &.sizeL {
    width: 326px;
  }
}
.arrow {
  height: 16px;
  width: 16px;
  &::before {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    background-color: var(--uiOverlayBackground);
    transform: rotate(45deg);
    position: relative;
    bottom: 5px;
    z-index: 0;
  }
}

.menu {
  &[data-popper-placement^='bottom'] .progressArrowColor {
    &::before {
      background-color: var(--uiBoxBackground) !important;
    }
  }
}

.content {
  z-index: 1;
}

.zDropdown {
  z-index: var(--zIndexDropdown);
}
.zHeader {
  z-index: var(--zIndexHeader);
}
.zModal {
  z-index: var(--zIndexModal);
}

@import "ui/styles/mixins";
.inputContainer {
  width: 100%;
}

.input {
  display: block;
  background-color: transparent;
  border: var(--outlineWidth) solid var(--uiInput);
  border-radius: 0;
  height: 50px;
  padding: 14px var(--size-4);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  transition: all 0.15s linear;

  &.small {
    padding: var(--size-4);
    height: 34px;
  }

  &::placeholder {
    color: var(--textDiscreet);
  }
  &:focus {
    border: var(--outlineWidth) solid var(--textDiscreet);
  }
  @media (hover: hover) {
    &:hover {
      border: var(--outlineWidth) solid var(--textDiscreet);
    }
  }

  &:disabled {
    background: var(--uiBoxBackground);
    color: var(--textSecondary);
    -webkit-text-fill-color: var(--textSecondary);
    opacity: 1;

    &::placeholder {
      color: var(--textSecondary);
    }
    @media (hover: hover) {
      &:hover {
        border: var(--outlineWidth) solid var(--uiInput);
      }
    }
  }
}

.textarea {
  padding: 14px var(--size-4);
  height: initial;

  &.autoexpand {
    // Hide scrollbars:
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
    resize: none;
  }
}

.label {
  display: inline-block;
  color: var(--textDiscreet);
  margin-top: var(--size-6);
  margin-bottom: var(--size-4);
}

.errorLabel {
  display: inline-block;
  color: var(--accentDanger);
  margin-top: var(--size-6);
  margin-bottom: var(--size-4);
}

.inputError {
  color: var(--accentDanger);
  border-color: var(--accentDanger);
  &:focus {
    border-color: var(--accentDanger);
  }
  @media (hover: hover) {
    &:hover {
      border-color: var(--accentDanger);
    }
  }
}

.inputWrap {
  position: relative;
  min-width: 24px;
  min-height: 1rem;
  & input {
    position: absolute;
    width: 100%;
    left: 0;
  }
}

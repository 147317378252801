$grid: 8.333%;

$grid-2: $grid * 2;
$grid-3: $grid * 3;
$grid-4: $grid * 4;
$grid-5: $grid * 5;
$grid-6: $grid * 6;
$grid-7: $grid * 7;
$grid-8: $grid * 8;
$grid-9: $grid * 9;
$grid-10: $grid * 10;
$grid-11: $grid * 11;
$grid-12: 100%;

.grid-container {
  display: flex;
  width: 100%;

  @include max600 {
    flex-direction: column;
  }
}

.grid-1 {
  width: $grid;
}
.grid-2 {
  width: $grid-2;
}
.grid-3 {
  width: $grid-3;
}
.grid-4 {
  width: $grid-4;
}
.grid-5 {
  width: $grid-5;
}
.grid-6 {
  width: $grid-6;
}
.grid-7 {
  width: $grid-7;
}
.grid-8 {
  width: $grid-8;
}
.grid-9 {
  width: $grid-9;
}
.grid-10 {
  width: $grid-10;
}
.grid-11 {
  width: $grid-11;
}
.grid-12 {
  width: $grid-12;
}

.grid-d-1 {
  width: 100%;
  @include min600 {
    width: $grid;
  }
}
.grid-d-2 {
  width: 100%;
  @include min600 {
    width: $grid-2;
  }
}
.grid-d-3 {
  width: 100%;
  @include min600 {
    width: $grid-3;
  }
}
.grid-d-4 {
  width: 100%;
  @include min600 {
    width: $grid-4;
  }
}
.grid-d-5 {
  width: 100%;
  @include min600 {
    width: $grid-5;
  }
}
.grid-d-6 {
  width: 100%;
  @include min600 {
    width: $grid-6;
  }
}
.grid-d-7 {
  width: 100%;
  @include min600 {
    width: $grid-7;
  }
}
.grid-d-8 {
  width: 100%;
  @include min600 {
    width: $grid-8;
  }
}
.grid-d-9 {
  width: 100%;
  @include min600 {
    width: $grid-9;
  }
}
.grid-d-10 {
  width: 100%;
  @include min600 {
    width: $grid-10;
  }
}
.grid-d-11 {
  width: 100%;
  @include min600 {
    width: $grid-11;
  }
}
.grid-d-12 {
  width: 100%;
  @include min600 {
    width: $grid-12;
  }
}

.grid-o-1 {
  margin-left: $grid;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-2 {
  margin-left: $grid-2;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-3 {
  margin-left: $grid-3;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-4 {
  margin-left: $grid-4;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-5 {
  margin-left: $grid-5;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-6 {
  margin-left: $grid-6;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-7 {
  margin-left: $grid-7;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-8 {
  margin-left: $grid-8;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-9 {
  margin-left: $grid-9;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-10 {
  margin-left: $grid-10;
  @include max600 {
    margin-left: 0;
  }
}
.grid-o-11 {
  margin-left: $grid-11;
  @include max600 {
    margin-left: 0;
  }
}

.grid-o-12 {
  margin-left: $grid-12;
  @include max600 {
    margin-left: 0;
  }
}

@import "ui/styles/mixins";
.container {
  width: 100%;
  max-width: 420px;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  h1 {
    text-align: left;
  }

  label {
    margin-bottom: var(--size-2);
    margin-top: var(--size-2);
    color: var(--textPrimary);
  }
}

.button,
.text {
  line-height: 24px;
}

.button {
  font-size: 14px;
  display: inline;

  > span {
    vertical-align: initial;
    display: inline;
  }
}

@import "ui/styles/mixins";
.container {
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--size-2);
  margin-top: var(--size-1);
  border-radius: 4px;

  @media (min-width: 600px) {
    width: 400px;
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--uiOutline);
    }
  }
}

.avatar {
  position: relative;
  svg {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    transition: 200ms opacity ease-in-out;
  }
  &.unseen {
    svg {
      opacity: 1;
    }
  }
}

.content {
  margin-left: var(--size-3);
  display: flex;
  flex-direction: column;
}

.date {
  margin-top: var(--size-1);
  color: var(--textDiscreet);
}

.link {
  font-weight: bold;
  transition: color 200ms ease-in-out;
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimary);
    }
  }
  &:active {
    color: var(--accentPrimaryActive);
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: var(--avatarRadius);
}

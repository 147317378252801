@import "ui/styles/mixins";
@import './spacing';
@import './colors';
@import './typography';
@import './utilities';
@import './grid';
@import './scrolling';
@import './tippyTheme';
@import './proseMirrorStyles';

// Variables
:root {
  --zIndexContent: 0;
  --zIndexDropdown: 2;
  --zIndexHeader: 3;
  --zIndexModal: 4;
  --zIndexDrawer: 10;
  --isHover: 0; // Used to propagate hover states across components
  --avatarRadius: 40%;
  --outlineWidth: 1px;
}

// Reset
html {
  box-sizing: border-box;
  background: var(--uiWhite);
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  background: var(--uiWhite);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 18px;
  line-height: 150%;
  font-weight: normal;
}

p {
  margin: 0;
}

img,
video {
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  transition: color 0.15s linear;
}
a,
a:focus,
a:hover {
  color: var(--textPrimary);
}
*:focus {
  outline: none;
}

blockquote {
  margin: 0;
  font-style: italic;
  color: var(--uiBlack);
}

blockquote p:before {
  content: '"';
}

blockquote p:after {
  content: '"';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

// removed the input, for specificity reasons
[type='number'] {
  -moz-appearance: textfield !important; /* Firefox */
  margin: 0;
  padding: 0;
}

button {
  color: black;
  cursor: pointer;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
} /* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

body,
input,
textarea,
input::placeholder,
textarea::placeholder {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include uiBase;
}

input,
select,
textarea {
  font-family: Inter, sans-serif;
  vertical-align: baseline;
}

textarea,
input:not([type='checkbox']):not([type='radio']) {
  -webkit-appearance: none; // Removes the shadow on ios safari
}

button {
  @include uiButton;
}

.text-button {
  background-color: transparent;
  border: 0 none;
  padding: 0;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.15s linear;
  color: var(--textDiscreet);
  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
    }
  }
}

// Body classes
body {
  transition: background-color 200ms ease;
}
body.bodyUiBoxBackground {
  background-color: var(--uiBoxBackground);
}

// Forms
.form {
  .displayHeader {
    text-align: left;
    margin-bottom: 10px;
  }
  label {
    display: inline-block;
    color: var(--textDiscreet);
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.date-input {
  display: block;
  background-color: var(--uiWhite);
  border: var(--outlineWidth) solid var(--uiInput);
  border-radius: 0;
  height: 100%;
  padding: 14px;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.15s linear;
  &:hover,
  &:focus {
    border: var(--outlineWidth) solid var(--textDiscreet);
  }
}

.text-input {
  display: block;
  background-color: transparent;
  border: var(--outlineWidth) solid var(--uiInput);
  border-radius: 0;
  height: 50px;
  padding: 14px 20px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.15s linear;
  &:hover,
  &:focus {
    border: var(--outlineWidth) solid var(--textDiscreet);
  }
}
textarea.text-input {
  line-height: 1.5;
  padding: 15px 20px;
}
.text-input::placeholder {
  font-size: 16px;
  line-height: 20px;
  color: var(--textDiscreet);
  opacity: 1;
}
textarea.text-input::placeholder {
  line-height: 1.5;
}
.nav-link {
  color: var(--textDiscreet);
  transition: color 200ms linear;
  @media (hover: hover) {
    &:hover {
      color: var(--uiBlack);
    }
  }
}

.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  @media (min-width: 1160px) {
    padding: 0;
  }
}

.relative {
  position: relative;
}

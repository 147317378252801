// UI
@mixin uiBase {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 145%;
  font-weight: normal;
  color: var(--textPrimary);
}

@mixin uiBaseFocus {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 145%;
  font-weight: normal;
  color: var(--textPrimary);
}

@mixin uiNav {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
}

@mixin uiObjectTitleFocus {
  font-family: 'Libre Baskerville', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
}

@mixin uiObjectTitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 165%;
}

@mixin uiButton {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

@mixin uiDiscreet {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}

// Display

@mixin displayHeader {
  font-family: 'Libre Baskerville', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 150%;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 24px;
  }
}

@mixin displayParagraph {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}

@mixin sectionHeader {
  font-family: 'Libre Baskerville', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.4;
}

// Font-style

@mixin italic {
  font-style: italic;
}

@mixin medium {
  font-weight: 500;
}

@mixin center {
  text-align: center;
}

@mixin left {
  text-align: left;
}

@mixin underline {
  text-decoration: underline;
}

.scrollingY {
  @include scrollingY;
}

.scrollingX {
  @include scrollingX;
}

.scrolling {
  @include scrolling;
}

/**
* Margin and padding mixin
*/

$sides: (
  'b': 'bottom',
  't': 'top',
  'l': 'left',
  'r': 'right',
);

:root {
  @for $i from 1 through 40 {
    --size-#{($i)}: #{($i * 4)}px;
  }
}

@each $prefix, $value in $sides {
  @for $i from 1 through 40 {
    .m-#{($i)} {
      margin: var(#{'--size-'+ ($i)});
    }
    .mx-#{($i)} {
      margin-left: var(#{'--size-'+ ($i)});
      margin-right: var(#{'--size-'+ ($i)});
    }
    .my-#{($i)} {
      margin-top: var(#{'--size-'+ ($i)});
      margin-bottom: var(#{'--size-'+ ($i)});
    }
    .m#{$prefix}- {
      &#{($i)} {
        margin-#{$value}: var(#{'--size-'+ ($i)});
      }
    }

    .p-#{($i)} {
      padding: var(#{'--size-'+ ($i)});
    }
    .px-#{($i)} {
      padding-left: var(#{'--size-'+ ($i)});
      padding-right: var(#{'--size-'+ ($i)});
    }
    .py-#{($i)} {
      padding-top: var(#{'--size-'+ ($i)});
      padding-bottom: var(#{'--size-'+ ($i)});
    }
    .p#{$prefix}- {
      &#{($i)} {
        padding-#{$value}: var(#{'--size-'+ ($i)});
      }
    }
  }
}

@import "ui/styles/mixins";
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  display: flex;
}

.laurelLeft {
  position: absolute;
  left: 0;
  pointer-events: none;
}

.laurelRight {
  position: absolute;
  right: 0;
  pointer-events: none;
}

@import "ui/styles/mixins";
.container {
  max-width: var(--modal-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--size-2);

  @media (min-width: 600px) {
    padding: 0;
  }
}

.circle {
  transition: stroke-dashoffset 500ms linear;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.back {
  @include resetButton;

  display: flex;
  align-items: center;
  color: var(--textDiscreet);

  transition: color 100ms linear;
  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
    }
  }

  svg {
    display: block;
    margin-right: var(--size-1);
  }
}

.progress {
  position: relative;
  display: flex;
}

.stepCount {
  @include uiDiscreet;
  color: var(--textDiscreet);
  position: absolute;
  width: 100%;
  right: 0;
  text-align: center;
  top: 15px;
}

@import "ui/styles/mixins";
.outer {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: var(--zIndexModal);

  @include min768 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--uiOverlayBackground);
  z-index: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  @include min768 {
    justify-content: center;
    align-items: center;
    background-color: var(--uiBackdrop);
    animation-duration: 250ms;
    animation-name: fade;
    animation-timing-function: ease;
  }
}

.close {
  width: 100%;
  background: var(--uiOverlayBackground);
  border: 0 none;
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  position: fixed;
  padding: 0 20px;
  // hack for safari scrolling issue (1)
  top: -2px;
  left: 0;
  z-index: 2;
  text-align: left;
  transition: all 0.15s linear;
  height: calc(55px + env(safe-area-inset-top));
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include min768 {
    width: 80px;
    background: transparent;
    border: 0 none;
    position: fixed;
    padding: 0;
    top: 25px;
    right: 25px;
    left: auto;
    justify-content: center;
    align-items: center;
  }
}
.icon {
  display: flex;
  align-items: center;
}
.closeLargeScreen {
  display: none;
  @include min768 {
    display: flex;
  }
}

.closeMedScreen {
  // account for -2px hack on the parent
  padding-top: 2px;
  @include min768 {
    display: none;
  }
}

.closeButton {
  @include resetButton;
  margin-right: auto;
}

.mobileActions {
  width: max-content;
  flex-grow: 0;
  display: flex;
  align-items: center;
  height: 100%;
  @include min768 {
    display: none;
  }
}

.mobileText {
  flex-grow: 0;
  margin-right: auto;
  color: var(--textSecondary);
  @include min768 {
    display: none;
  }
}

.scrollable {
  @include scrollingY;
  width: 100%;

  // (2) 55px-2px
  // include the negative margin for modal. refer to (1) above
  margin: 53px auto 0 auto;
  height: calc(var(--app-height) - 53px - env(safe-area-inset-top) - env(safe-area-inset-bottom));

  @include min768 {
    margin: 0 auto;
    height: calc(var(--app-height) - env(safe-area-inset-top));
  }
}

.inner {
  width: 100%;
  animation-duration: 150ms;
  animation-name: fade;
  animation-timing-function: linear;
  height: 100%;
  pointer-events: all;

  @include min768 {
    pointer-events: none;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.padding {
  width: 100%;
  margin: 0 auto;

  @include min768 {
    margin-top: auto;
    margin-bottom: auto;
    padding: 80px 0;
    &.extraPadding {
      padding: 140px 0;
    }
  }
}

.contentOuter {
  position: relative;
  z-index: 1;
}

.contentInner {
  width: 100%;
  margin: 0 auto;
  max-width: var(--modal-max-width);
  border-radius: 2px;
  background-color: var(--uiOverlayBackground);
  pointer-events: all;
}

.contentPadding {
  padding: var(--size-5) var(--size-5);
  @include min768 {
    padding: var(--size-12) var(--size-14);
    background-color: var(--uiOverlayBackground);
  }
}

/****
**** Wizzard mode
****/

.wizardMode {
  .backdrop {
    justify-content: flex-start;
  }

  .scrollable {
    margin: 0 auto;
    height: calc(100vh - env(safe-area-inset-top));
  }

  .contentOuter {
    position: relative;
    @include min768 {
      min-height: 0;
      padding-bottom: 58px; // To vertically center the modal content
    }
  }
}

.wizzardWrapper {
  padding: 10px 0;
  background: var(--uiBackdrop);
  position: relative;

  &.roomForTopBar {
    padding: 64px 0 var(--size-3) 0;
  }

  @include min768 {
    background: transparent;
    max-width: var(--modal-max-width);
    width: 100%;
    margin: 0 auto;
    pointer-events: all;

    &.roomForTopBar {
      padding: 0 0 var(--size-3) 0;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

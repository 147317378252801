@import "ui/styles/mixins";
.bookContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 60px;
  overflow-x: hidden;
  margin-top: calc(-1 * var(--outlineWidth));
  // TODO: collapsing the borders is a little ugly
  > div {
    margin-top: calc(-1 * var(--outlineWidth));
  }

  > div:nth-child(1) {
    margin-top: 0;
  }

  > div:nth-child(2) {
    margin-top: 0;
  }

  > div:nth-child(odd) {
    margin-right: calc(-1 * var(--outlineWidth));
  }

  @media (max-width: 599px) {
    > div:nth-child(odd) {
      border-left: none;
    }

    > div:nth-child(even) {
      border-right: none;
    }
  }
}

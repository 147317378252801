@import "ui/styles/mixins";
.divider {
  height: var(--outlineWidth);
  background: var(--uiOutline);
  border: 0;
  margin: 0;
  padding: 0;
}

.semi {
  margin-left: var(--size-4);
  margin-right: var(--size-4);
}

.spacingSmall {
  margin-top: 6px;
  margin-bottom: 5px;
}

.spacingLarge {
  margin-top: var(--size-4);
  margin-bottom: var(--size-4);
}

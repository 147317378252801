@import "ui/styles/mixins";
.footer {
  width: 100%;
  max-width: 1160px;
  padding: 30px 0 120px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  color: var(--textDiscreet);
  position: relative;
  // Hack for safari flexbox with inter font
  // https://github.com/rsms/inter/issues/321
  letter-spacing: 0.000001px;
  @media (min-width: 600px) {
    padding: 30px 20px 160px;
  }

  .column,
  .contact {
    flex: 0 0 50%;

    @media (min-width: 900px) {
      flex: 0 0 20%;
    }
  }
  .label {
    margin-top: var(--size-10);
  }
}

.row {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 900px) {
    padding: 0;
  }
}

.column,
.contact {
  .label {
    margin-bottom: var(--size-5);
    color: var(--uiBlack);
  }
  ul {
    list-style: none;
  }
  li {
    margin-bottom: var(--size-2);
  }
  p {
    margin-bottom: var(--size-5);
  }
  a {
    color: var(--textDiscreet);
  }
}

.mark {
  position: absolute;
  right: 20px;
  bottom: calc(70px + env(safe-area-inset-bottom));
}

.divider {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background-color: var(--uiOutline);
  margin-top: 70px;
}

.banner {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 100px var(--size-5);
  background-color: var(--uiBoxBackground);

  @media (min-width: 600px) {
    margin: 70px auto 0;
  }
}

.bannerInner {
  max-width: 312px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.apps {
  max-width: 340px;
  margin-top: var(--size-8);
  display: flex;
  gap: var(--size-5);

  a {
    display: block;
    flex: 1 0 0;
  }
}

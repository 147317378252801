.tippy-box[data-theme~='literal'] {
  padding: 0;
  color: var(--textPrimary);
}
.tippy-box[data-theme~='literal'] > .tippy-content {
  @include scrollingY;
  padding: 0;
  border-radius: var(--size-1);
  max-width: 360px;
  min-width: 250px;
  background-color: var(--uiWhite);
  filter: drop-shadow(0px 0px var(--size-2) var(--shadowColor));
  z-index: 1;
  max-height: 345px;
  @media (min-width: 600px) {
    width: 360px;
  }
}

.tippy-box[data-theme~='literal'][data-placement^='top'] > .tippy-arrow {
  z-index: 1;
}

.tippy-box[data-theme~='literal'][data-placement^='bottom'] > .tippy-arrow {
  z-index: 1;
}

.tippy-box[data-theme~='literal'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--uiWhite);
  position: absolute;
  left: var(--size-2);
}
.tippy-box[data-theme~='literal'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--uiWhite);
  position: absolute;
  left: var(--size-2);
}

.tippy-box[data-theme~='literal'][data-placement^='top-end'] > .tippy-arrow::before {
  border-top-color: var(--uiWhite);
  left: 0;
}
.tippy-box[data-theme~='literal'][data-placement^='bottom-end'] > .tippy-arrow::before {
  border-bottom-color: var(--uiWhite);
  left: 0;
}

@import "ui/styles/mixins";
.container {
  display: flex;
  align-items: center;
  padding: var(--size-4) var(--size-6) var(--size-4) var(--size-4);
  background-color: var(--uiBoxBackground);
  justify-content: space-between;
  border-radius: var(--size-1);
  &:hover {
    cursor: pointer;
  }
}

.left {
  display: flex;
  align-items: center;
}

@import "ui/styles/mixins";
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-2);
  margin: var(--size-8) 0 var(--size-6) 0;
  white-space: nowrap;

  h2 {
    white-space: normal;
  }

  @media (min-width: 600px) {
    margin: var(--size-14) 0 var(--size-8) 0;
  }
}

.mobilePadding {
  padding: 0 var(--size-5);
  @media (min-width: 600px) {
    padding: 0;
  }
}

.left {
  display: flex;
  align-items: flex-end;
  gap: var(--size-1);

  @media (min-width: 600px) {
    gap: var(--size-5);
  }
}

@import "ui/styles/mixins";
.track {
  width: 100%;
  @include scrollingX;
  overflow-y: hidden;
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  display: flex;

  &.sticky {
    position: sticky;
    top: 55px;
    background-color: var(--uiWhite);
    z-index: 1;

    @media (min-width: 600px) {
      top: 60px;
    }

    @media (min-width: 900px) {
      top: 80px;
    }
  }
}

.tabBar {
  margin: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: max-content;
  padding-left: var(--size-5);
  padding-right: var(--size-5);

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
    padding: 0;
  }

  &.right {
    justify-content: flex-end;
    padding: 0;
  }

  &.full-width-left {
    justify-content: flex-start;
    padding-left: var(--size-5);
    @media (min-width: 1120px) {
      padding: 0;
    }
  }
}

.tab {
  background-color: transparent;
  border: 0 none;
  padding: 20px 0 21px;
  margin-right: 20px;
  color: var(--textDiscreet);
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  transition: all 0.15s linear;

  &:last-child {
    margin-right: 0;
  }
  @media (hover: hover) {
    &:hover {
      color: var(--uiBlack);
    }
  }
}

.active {
  color: var(--textBlack);
}

.tabContent {
  z-index: 0;
  position: relative;
  width: 100%;
  scroll-margin-block-start: 126px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 180px); // view height minus all sticky things

  @media (min-width: 900px) {
    scroll-margin-block-start: 150px;
  }
}

.buttonLeft {
  margin-right: auto;
}

.buttonRight {
  margin-left: auto;
}

@import "ui/styles/mixins";
.view {
  overflow-x: hidden; // Needed?
}

.track {
  @include scrollingX;
  display: flex;

  > * {
    flex-shrink: 0;
  }
}

.track.snap {
  scroll-snap-type: x proximity;

  > * {
    scroll-snap-align: start;
  }
}

.track.center {
  justify-content: center;
}

.controls {
  @include mobilePadding;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-top: var(--size-5);

  > div {
    height: 24px;
  }

  @media (min-width: 600px) {
    justify-content: space-between;
  }
}

.active {
  cursor: grabbing;

  a:hover {
    cursor: grabbing !important;
  }
}

.dots {
  display: flex;
  justify-content: center;
  gap: var(--size-1);
  overflow-x: hidden;

  @media (min-width: 600px) {
    justify-content: flex-start;
  }

  svg {
    width: 6px;
    height: 6px;

    cursor: pointer;
    transition: transform 150ms ease;
    &:hover {
      transform: scale(1.2);
      circle {
        fill: var(--textPrimary);
      }
    }
  }
}

.arrowButtons {
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
}

.mobilePadding {
  padding-left: var(--size-5);
  padding-right: var(--size-5);
  scroll-padding-left: var(--size-5);

  @media (min-width: 1120px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.containerWidth {
  @media (min-width: 1120px) {
    padding-left: calc(50% - 560px);
    padding-right: calc(50% - 560px);
  }
}

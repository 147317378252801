@import "ui/styles/mixins";
.container {
  h2 {
    text-align: left;
  }

  hr {
    margin-left: 0;
    margin-right: 0;
  }
}

@import "ui/styles/mixins";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C4C2BAFF' stroke-width='1.6' stroke-dasharray='2%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C4C2BAFF' stroke-width='3.2' stroke-dasharray='2%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  padding: 32px 24px;

  margin: var(--margin-prop);
  @media (min-width: 600px) {
    margin: var(--margin-prop-600);
  }
  @media (min-width: 900px) {
    margin: var(--margin-prop-900);
  }
  @media (min-width: 1120px) {
    margin: var(--margin-prop-1120);
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: var(--size-4)
}

.buttons {
  display: flex;
  justify-content: center;
  gap: var(--size-2);
  width: 100%;
}

.text {
  text-align: center;
  color: var(--textDiscreet);
  max-width: 280px;
}

.illustration {
  height: 140px;
  width: auto;
}
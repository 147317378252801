@import "ui/styles/mixins";
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--uiWhite);
  display: flex;
  align-items: center;
  z-index: 1;

  @media (min-width: 900px) {
    padding-left: 20px;
    padding-right: 18px;
  }
  @media (hover: hover) {
    &:hover {
      .icon svg {
        stroke: var(--textPrimary);
      }
    }
  }
}

:global(.bodyUiBoxBackground) .container {
  background-color: var(--uiBoxBackground);

  .clear {
    background-color: var(--uiBoxBackground);
  }
}

.spacer {
  height: 118px;
  transition: height 200ms ease;

  &.active {
    height: 55px;
  }

  @media (min-width: 600px) {
    display: none;
  }
}

.withBorder {
  border: 1px solid var(--uiOutline);
}

.input {
  background-color: transparent;
  height: 50px;
  padding: 14px 20px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.15s linear;

  transition: all 0.15s linear, width 200ms ease;
  position: relative;
  padding-left: 43px;
  height: 55px;
  border: 0;
  font-size: 14px;
  padding-right: 20px;

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: var(--textDiscreet);
    opacity: 1;
  }

  &:hover {
    border: 0;
  }
}

.input.active,
.input:focus {
  border: 0;
}

.input:placeholder-shown {
  text-overflow: ellipsis;
}

.icon {
  position: absolute;
  display: block;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 11px;
  pointer-events: none;

  @media (min-width: 600px) {
    left: 11px;
  }

  @media (min-width: 900px) {
    left: 22px;
  }

  svg {
    display: block;
    transition: all 0.15s linear;
  }
}
.icon.active svg {
  stroke: var(--textPrimary);
  transition: stroke 150ms linear;
}

.clear {
  height: 30px;
  background-color: var(--uiWhite);
  border: 0 none;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;

  @media (min-width: 600px) {
    left: 8px;
  }

  @media (min-width: 900px) {
    left: 19px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  svg path {
    stroke: var(--textDiscreet);
    transition: stroke 150ms linear;
  }
  @media (hover: hover) {
    &:hover svg path {
      stroke: var(--textPrimary);
    }
  }
}

.mobileInput {
  position: fixed;
  // height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--zIndexHeader);
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  background-color: var(--uiWhite);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 600px) {
    display: none;
  }
}

@import "ui/styles/mixins";
.button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 var(--size-5);
  height: 48px;
  border-radius: 2px;
  border: var(--outlineWidth) solid var(--uiBlack);
  text-decoration: none;
  opacity: 1;
  color: var(--textWhite);
  cursor: pointer;
  transition: all 0.15s linear;
  &:disabled {
    .text {
      filter: blur(1px);
      opacity: 0.35;
    }
    opacity: 0.5;
    cursor: default;
  }
}

.alignCenter {
  text-align: center;
  justify-content: center;
}

.alignLeft {
  text-align: left;
  justify-content: space-between;
}

.primary {
  background: var(--uiBlack);
  .icon {
    color: var(--uiWhite);
  }
  &:disabled {
    .icon {
      filter: invert(100%) blur(1px);
      opacity: 0.35;
    }
  }
  @media (hover: hover) {
    &:hover:enabled {
      background: var(--textPrimary);
      border-color: var(--textPrimary);
    }
  }
  &:active:enabled {
    background: var(--uiBase);
    border-color: var(--uiBase);
  }
}

.focus {
  background: var(--accentPrimary);
  border-color: var(--accentPrimary);

  &:disabled {
    .icon {
      filter: blur(1px);
      opacity: 0.35;
    }
  }
  @media (hover: hover) {
    &:hover:enabled {
      background: var(--accentPrimaryHover);
      border-color: var(--accentPrimaryHover);
    }

  }
  &:active:enabled {
    background: var(--accentPrimaryActive);
    border-color: var(--accentPrimaryActive);
  }
  &:disabled {
    background: var(--accentPrimaryDisabled);
    border-color: var(--accentPrimaryDisabled);
    opacity: 1;
  }
}

.secondary {
  background: var(--uiOverlayBackground);
  border-color: var(--uiBlack);
  color: var(--uiBlack);
  @media (hover: hover) {
    &:hover:enabled {
      background: var(--textPrimary);
      border-color: var(--textPrimary);
      color: var(--textWhite);
    }
  }
  &:active:enabled {
    background: var(--uiBase);
    border-color: var(--uiBase);
    color: var(--textWhite);
  }
  &:disabled {
    border-color: var(--textDisabled);
    color: var(--textDisabled);
    opacity: 1;
    .text {
      opacity: 0.5;
    }
  }
}

.tertiary {
  background: transparent;
  border-color: var(--uiInput);
  color: var(--textDiscreet);
  @media (hover: hover) {
    &:hover:enabled {
      background: var(--uiWhite);
      border-color: var(--textPrimary);
      color: var(--textPrimary);
    }
  }
  &:active:enabled {
    background: var(--uiWhite);
    border-color: var(--uiSecondary);
    color: var(--uiSecondary);
  }
  &:disabled {
    opacity: 1;
    border-color: var(--textDisabled);
    color: var(--uiBase);
    .text {
      opacity: 0.5;
    }
  }
}

.focus-outline {
  background: var(--uiWhite);
  border-color: var(--accentPrimary);
  color: var(--accentPrimary);
  min-width: 105px;
  &.mini {
    min-width: 85px;
  }
  @media (hover: hover) {
    &:hover {
      border-color: var(--textPrimary);
      color: var(--textPrimary);
    }
  }
  &:active {
    background: var(--uiBase);
    border-color: var(--uiBase);
  }
}

.mini {
  padding: 0 10px;
  height: 34px;
}

.hasIcon {
  display: flex;
}

.icon {
  display: flex;
  align-items: center;

  + div {
    margin-left: var(--size-1);
  }
}

.stretch {
  width: 100%;

  .icon {
    + div {
      margin-left: var(--size-2);
    }
  }
}

@import "ui/styles/mixins";
.header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--uiWhite);
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  z-index: var(--zIndexHeader);

  @media (min-width: 900px) {
    height: 80px;
    padding: 0 30px;
  }
}

:global(.bodyUiBoxBackground) .header {
  background-color: var(--uiBoxBackground);
}

.spacer {
  height: 60px;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
  @media (min-width: 900px) {
    height: 80px;
  }
}

.hideHeaderOnMobile {
  display: none;
  @media (min-width: 600px) {
    display: block;
  }

  + .spacer {
    display: none;
    @media (min-width: 600px) {
      display: block;
    }
  }
}

.inner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left,
.right {
  display: flex;
  align-items: center;
  height: 100%;
}
.left {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;

  @media (min-width: 600px) {
    flex: 0 1 auto;
  }
}

.right {
  flex: 0 1 auto;
  justify-content: flex-end;
}

.authenticated .right {
  padding-left: 10px;

  @media (min-width: 900px) {
    padding-left: 20px;
  }
}

.search {
  display: flex;
  align-items: flex-start;
  width: 100%;
  transition: all 150ms ease-in-out;
  height: 100%;

  .searchInner {
    height: 100%;
    max-width: 50px;
    width: 100%;
  }

  @include min900 {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .searchInner {
      max-width: inherit !important;
      border-right: none;
    }
  }
}

.search.searchFocused {
  .searchInner {
    max-width: inherit !important;
    border-right: none;
  }
}
.search.searchFocused ~ div {
  @media screen and (max-width: 900px) {
    display: none;
  }
}

.authenticated .search {
  @media (min-width: 900px) {
    border-right: var(--outlineWidth) solid var(--uiOutline);
  }
}

.links {
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    padding: 0 var(--size-2);
  }

  @media (min-width: 900px) {
    padding: 0 var(--size-5);
    display: flex;
    height: 100%;
  }
}
.link {
  padding: 10px var(--size-3);
  text-decoration: none;
  color: var(--textDiscreet);

  @media (min-width: 900px) {
    padding: 10px 12px;
    margin: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}

.standaloneButton {
  border: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  cursor: pointer;
  position: relative;
  border-left: var(--outlineWidth) solid var(--uiOutline);
  color: var(--textDiscreet);
  transition: color 0.15s linear;
  white-space: nowrap;
  padding: 0 var(--size-4);

  @media (min-width: 900px) {
    padding: 0 30px;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--uiBlack);
    }
  }
}
.authenticated .standaloneButton {
  margin-left: 10px;
  @media (min-width: 900px) {
    margin-left: 20px;
  }
}

.greenButton {
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding: 0 16px;
  background-color: var(--accentPrimary);
  color: var(--uiWhite);
  transition: background-color 0.15s linear, color 0.15s linear;
  cursor: pointer;

  svg {
    display: none;
  }

  @media (min-width: 350px) {
    svg {
      display: block;
    }
  }

  @media (min-width: 900px) {
    padding: 0 28px;
    margin-left: 0;
    margin-right: -30px;
  }

  svg {
    margin-left: 8px;

    @media (min-width: 900px) {
      margin-left: 10px;
    }
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--textPrimary);
      color: var(--uiWhite);
    }
  }
}

.iconItems {
  border-left: var(--outlineWidth) solid var(--uiOutline);
  height: 100%;
  display: flex;
  margin-left: var(--size-4);
  padding-left: var(--size-4);

  @include min900 {
    padding-left: var(--size-5);
    margin-left: var(--size-5);
  }
}

.icon {
  background: transparent;
  margin: 0;
  border: 0;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--textDiscreet);
  transition: color 150ms linear;
  padding: 0 var(--size-2);

  @include min900 {
    padding: 0 var(--size-3);
  }

  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
    }
  }

  &.unseen {
    color: var(--textBlack);
  }
}

.active {
  color: var(--textPrimary);
}

@import "ui/styles/mixins";
.tooltip {
  max-width: calc(100vw - 100px);
  border-radius: 2px;
  padding: var(--size-2) var(--size-3);
  height: 36px;
  color: var(--uiWhite);
  position: fixed;
  white-space: nowrap;
  transition: all 150ms linear;
  opacity: 0;
  visibility: hidden;
  z-index: var(--zIndexDropdown);
}

.heightAuto {
  height: auto;
}

.object:hover + div.disableOnNonHoverDevices {
  @media (hover: hover) {
    opacity: 1;
    visibility: visible;
  }
}
.object:hover + div.enableOnNonHoverDevices {
  opacity: 1;
  visibility: visible;
}

.gray {
  background-color: var(--textDiscreet);

  .arrow::before {
    background-color: var(--textDiscreet);
  }
}

.white {
  color: var(--textPrimary);
  background-color: var(--uiWhite);
  filter: drop-shadow(0px 0px 8px var(--shadowColor));

  .arrow::before {
    background-color: var(--uiWhite);
  }
}

.arrow {
  position: absolute;
  z-index: -1;
  height: 16px;
  width: 16px;

  &::before {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    transform: rotate(45deg);
    position: relative;
  }
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
  left: -4px;
}

.text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

// For rich text placeholders
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.ProseMirror p.is-editor-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--textDiscreet);
  pointer-events: none;
  height: 0;
}

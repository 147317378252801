@import "ui/styles/mixins";
.outer {
  position: relative;
  width: 100%;
}

.container {
  overflow: hidden;
  margin: 0;
  position: relative;
}
.limited {
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.button {
  @include resetButton;
  background-color: var(--uiWhite);
  border: 0 none;
  padding: 4px 0 0;
  color: var(--textDiscreet);
  border-bottom: 1px solid var(--textDiscreet);
  transition: color 0.15s linear, border-bottom 0.15s linear;
  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
      border-bottom: 1px solid var(--textDiscreet);
    }
  }
}

.button::before {
  width: 100px;
  height: 22px;
  display: inline-block;
  content: '';
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  @media (prefers-color-scheme: dark) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  position: absolute;
  top: 0;
  left: -100px;
  pointer-events: none;
}

.button::after {
  width: 15px;
  height: 22px;
  display: inline-block;
  content: '';
  background-color: var(--uiWhite);
  position: absolute;
  top: 0;
  right: -15px;
  pointer-events: none;
}

.noFade::before {
  width: 0;
  height: 0;
}

.noMargin {
  margin: 0;
}

:global(.bodyUiBoxBackground) {
  .button {
    background-color: var(--uiBoxBackground);
  }
  .button::before {
    background: linear-gradient(to right, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 1) 100%);
    @include darkMode {
      background: linear-gradient(to right, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 1) 100%);
    }
  }
  .button::after {
    background-color: var(--uiBoxBackground);
  }
}

@import "ui/styles/mixins";
.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: var(--size-10);
}

.fullPage {
  height: calc(70vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 0;
  top: 0;
}

.background {
  width: 100%;
  height: calc(70vh - 240px);
  display: block;
  position: absolute;
  top: -5px; // no idea why, but it aligns with the footer
  left: 0;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  margin-top: 240px;

  .backgroundInner {
    display: block;
    position: absolute;

    width: 100%;
    height: 100%;
    background-image: url('/img/error-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }

  @include min600 {
    top: 40px; // no idea why, but it aligns with the footer
  }
}

@import "ui/styles/mixins";
.book {
  display: flex;
  text-decoration: none;
  --isHover: 0;
  h4 {
    font-weight: 400;
    font-size: 14px;
    @include limitTextLines(1);
  }
  @media (hover: hover) {
    &:hover {
      --isHover: 1;
    }
  }
}

.image {
  width: 28px;
  flex: 0 0 28px;
  margin-right: 14px;
  align-self: flex-start;
  min-height: 42px;
  @media (hover: hover) {
    transform: translateY(calc(var(--isHover) * -0.5px));
    transition: opacity 300ms ease;
    -webkit-transform: translate3d(0, calc(var(--isHover) * -1px), 0); // Safari suff
    transition: transform 300ms ease;
  }
}

.authors {
  color: var(--textDiscreet);
  @include limitTextLines(1);
}

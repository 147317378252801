@import "ui/styles/mixins";
.container {
  display: none;
  @media (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 var(--size-5);
    width: 100%;
  }
  @media (min-width: 1160px) {
    padding: 0;
  }
}

.showMobile {
  display: flex;
}

.extras {
  padding-left: var(--size-2);
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
}

.image {
  display: block;
  border-radius: var(--avatarRadius);
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.divider {
  color: var(--textDiscreet);
  padding: 0 10px;
  opacity: 0.3;
}

.link,
.text {
  color: var(--textDiscreet);
  &:last-child {
    opacity: 0.6;
  }
}

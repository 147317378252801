@import "ui/styles/mixins";
.tag {
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding: 0 9px;
  border-radius: 60px;
  transition: background-color 300ms ease, border-color 300ms ease;

  @media (min-width: 600px) {
    height: 34px;
    padding: 0 16px 0 10px;
  }

  svg {
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 4px;

    @media (min-width: 600px) {
      width: 20px;
      height: 20px;
    }
  }

  &.iconOnly {
    padding: 0 6px;
    @media (min-width: 600px) {
      padding: 0 7px;
    }
    svg {
      margin-right: 0;
    }
  }

  &.textOnly {
    padding: 0 10px;
  }
}

@media (hover: hover) {
  .hover {
    &:hover {
      cursor: pointer;
      background-color: var(--uiOutline);
      border-color: var(--uiOutline);
    }
  }
}

.text {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Variants:

.solid {
  background-color: var(--uiBoxBackground);
  border: 1px solid var(--uiBoxBackground);
}

.outline {
  border: 1px solid var(--uiOutline);
  .text {
    color: var(--textDiscreet);
  }
}

.solid-alt {
  background-color: var(--uiOutline);
  border: 1px solid var(--uiOutline);

  @include darkMode {
    background-color: var(--uiOverlayBackground);
    border: 1px solid var(--uiOverlayBackground);
  }
}

// Discreet is used to show a hierarchy between tags. Example: Tag1, Tag2, "+12 tags". The last one would be discreet to show it's different...
.discreet {
  color: var(--textDiscreet);
  .text {
    color: var(--textDiscreet);
  }
}

@import "ui/styles/mixins";
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: var(--zIndexModal);
  background-color: var(--uiBackdrop);
  width: 100vw;
  height: 100%;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: var(--zIndexModal);
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
  // Long press should not select the text
  user-select: none;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  @include scrollingY;

  &:after {
    content: '';
    width: 100%;
    height: 110px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--uiOverlayBackground);
  }
}

.content {
  border-top: var(--uiOutline) solid var(--outlineWidth);
  z-index: 1;
  background-color: var(--uiOverlayBackground);
  margin-top: calc(100vh - 360px);
}

.close {
  height: 60px;
  background-color: var(--uiOverlayBackground);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--zIndexHeader);
  align-self: flex-end;
  color: var(--textDiscreet);
  border-top: 1.5px solid var(--uiOutline);

  button {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.content {
  padding-bottom: var(--size-8);
}

.noScroll {
  justify-content: flex-end;
  &:before {
    visibility: hidden;
  }
  .content {
    margin-top: auto;
    padding-bottom: 0;
  }
}

.close {
  &:before {
    content: '';
    position: absolute;
    top: -58.5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 57px;
    pointer-events: none;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

    @media (prefers-color-scheme: dark) {
      background-image: linear-gradient(180deg, rgba(39, 39, 39, 0) 0%, rgba(39, 39, 39, 1) 100%);
    }
  }
}

// animation classes

.optionsEnter {
  transform: translateY(800px);
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
}

.optionsEnterActive {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: transform 200ms;
  -webkit-transition: -webkit-transform 200ms;
  transition: ease-in-out transform 200ms;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
}

.optionsExit {
  transform: translateY(0);
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
}

.optionsExitActive {
  -webkit-transform: translateY(800px);
  transform: translateY(800px);
  -webkit-transition: transform 200ms;
  -webkit-transition: -webkit-transform 200ms;
  transition: ease-in-out transform 200ms;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
}

.backdropEnter {
  opacity: 0;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
}

.backdropEnterActive {
  opacity: 1;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
  transition: all ease-in-out 200ms;
}

.backdropExit {
  opacity: 1;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
}

.backdropExitActive {
  opacity: 0;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
  transition: all ease-in-out 200ms;
}

@import "ui/styles/mixins";
.layout {
  display: flex;
  flex-direction: column;
}

.container {
  @include container;
  min-height: calc(90vh - 80px); // minus header height
  &.fullContainer {
    max-width: 100%;
  }
}

.content {
  width: 100%;
}
.footer {
  margin: 20px 0;
}

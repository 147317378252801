:root,
:root.light {
  // Text
  --textPrimary: #444340;
  --textSecondary: #838178;
  --textDiscreet: #9a988b;
  --textBlack: #000000;
  --textDisabled: #c4c2ba;
  --textWhite: #ffffff;

  // UI
  --uiBase: #c4c2ba;
  --uiInput: #e1dddd;
  --uiOutline: #eeeeee;
  --uiBaseLight: #ebebeb;
  --uiWhite: #ffffff;
  --uiBlack: #000000;
  --uiSecondary: #6c6c67;
  --uiBoxBackground: #f8f8f8;
  --uiHover: #f5f5f5;
  --uiBackdrop: rgba(245, 245, 245, 0.97);
  --uiOverlayBackground: #ffffff;

  // Accent
  --accentPrimary: #278458;
  --accentAlert: #f69438;
  --accentDanger: #e14d4d;

  // Unnamed in Figma
  --accentPrimaryHover: #389e6e;
  --accentPrimaryActive: #216545;
  --accentPrimaryDisabled: #739a88;

  --shadowColor: rgba(0, 0, 0, 0.15);

  // Identity Gradients
  --mediterranean: linear-gradient(175.27deg, #019bbd -1.64%, #fdaea1 54.1%, #fed1b4 73.58%, #ffe0c3 85.36%);
  --grassy: linear-gradient(175.27deg, #93d4ff -1.64%, #e2f1b7 59.08%, #e8f09d 85.36%);
  --atlantis: linear-gradient(175.27deg, #adcbf9 -1.64%, #c2f5fc 59.08%, #c1f2d3 85.36%);
  --miami: linear-gradient(175.27deg, #e6a9e8 -1.64%, #81caff 63.61%, #f9d0f0 85.36%);
  --sunny: linear-gradient(175.27deg, #fdaea1 -1.64%, #ffefb8 38.24%, #ffe0c3 85.36%);
  --barbie: linear-gradient(175.27deg, #ff99be -1.64%, #dcc7f5 61.8%, #faafe0 85.36%);
  --smokey: linear-gradient(175.27deg, #b0daf2 -1.64%, #fff4cf 47.3%, #b1d6b0 85.36%);
}

@mixin darkColors {
  --textPrimary: #f9f9f9;
  --textSecondary: rgba(190, 190, 190, 1);
  --textDiscreet: rgba(172, 172, 172, 1);
  --textBlack: rgba(255, 255, 255, 1);
  --textDisabled: rgba(88, 88, 88, 1);
  --textWhite: rgba(0, 0, 0, 1);
  --uiBase: rgba(192, 192, 192, 1);
  --uiInput: #7c7c7c;
  --uiBaseLight: rgba(100, 100, 100, 1); // todo - approximated
  --uiOutline: #383838;
  --uiBaseight: rgba(210, 210, 210, 1);
  --uiBlack: #f0f0f0;
  --uiSecondary: rgba(226, 226, 226, 1);
  --uiBoxBackground: #131313;
  --uiBoxight: rgba(39, 39, 39, 1);
  --uiHover: #292929;
  --uiWhite: rgba(0, 0, 0, 1);
  --accentAlert: rgba(246, 148, 56, 1);
  --accentDanger: rgba(225, 77, 77, 1);
  --accentPrimary: rgba(51, 187, 122, 1);
  --uiBackdrop: hsl(0deg 0% 0% / 91%);
  --shadowColor: rgba(255, 255, 255, 0.07);
  --uiOverlayBackground: #272727;
}

:root {
  @media (prefers-color-scheme: dark) {
    @include darkColors;
  }

  &.dark {
    @include darkColors;
  }
}

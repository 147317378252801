@mixin container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
}

@mixin centerContainer($maxWidth) {
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
}

@mixin mobilePadding {
  padding-left: var(--size-5);
  padding-right: var(--size-5);

  @media (min-width: 1160px) {
    padding-left: 0;
    padding-right: 0;
  }
}


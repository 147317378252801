@import "ui/styles/mixins";
/**
 * SHARED STYLES:
 * (both image and fallback)
 */

.outer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.image {
  color: transparent;
}

.image,
.fallbackImage {
  user-select: none;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}

// Borders and border-radius
.image,
.fallbackImage {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &.assetSize-600 {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &.assetSize-200,
  &.assetSize-300 {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &.assetSize-100 {
    border-radius: 1px;
  }
}

// Shadows
.image,
.fallbackImage {
  transition: filter 300ms ease;
  filter: drop-shadow(
    0 calc((1.5 + var(--isHover)) * 4px) calc((3 + var(--isHover)) * 4px)
      rgba(0, 0, 0, calc((2 + var(--isHover)) * 0.06))
  );

  &.assetSize-100,
  &.assetSize-200,
  &.assetSize-300 {
    filter: drop-shadow(
      0px calc((0 + var(--isHover)) * 2px) calc((5 + var(--isHover)) * 2px)
        rgba(0, 0, 0, calc((1.5 + var(--isHover)) * 0.03))
    );
  }

  @include darkMode {
    filter: none;
    &.assetSize-100,
    &.assetSize-200,
    &.assetSize-300 {
      filter: none;
    }
  }
}

/**
 * FALLBACK ONLY STYLES:
 */
.fallbackImage {
  aspect-ratio: 2 / 3;
  background-color: var(--textPrimary);
  text-align: left;
  padding: calc(var(--myWidth) / 10) calc(var(--myWidth) / 15);
  color: var(--uiWhite);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  // Prop: stretch
  &.horizontal {
    width: 100%;
  }
  &.vertical {
    height: 100%;
  }
}
.author {
  color: var(--textDiscreet);
  font-size: calc(var(--myWidth) * 0.068);
  line-height: calc(var(--myWidth) * 0.1);
  margin-bottom: auto;
}
.title {
  @include sectionHeader;
  font-size: calc(var(--myWidth) * 0.098);
  margin-bottom: 5%;
  @include limitTextLines(2);
  @media (min-width: 900px) {
    -webkit-line-clamp: 4;
  }
}
.subtitle {
  @include sectionHeader;
  font-size: calc(var(--myWidth) * 0.068);
  font-style: italic;
  @include limitTextLines(3);
  @media (min-width: 900px) {
    -webkit-line-clamp: 4;
  }
}

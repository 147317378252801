@import "ui/styles/mixins";
.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.loading:before {
  position: absolute;
  top: -1px;
  content: '';
  display: block;
  background-color: var(--accentPrimary);
  height: 1px;
  width: 0;
  animation: loading 1800ms linear infinite;
}

@keyframes loading {
  100% {
    width: 100%;
  }
}

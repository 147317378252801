@import "ui/styles/mixins";
.container {
  display: inline-flex;
  align-items: center;
}

.fileName {
  margin-left: var(--size-2);
}

.icon {
  display: flex;
  align-items: center;
  margin-right: var(--size-1);
}

label.input {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 var(--size-5);
  height: 48px;
  border-radius: 2px;
  text-decoration: none;
  opacity: 1;
  transition: all 150ms linear;
  background: transparent;
  border: 1.6px solid var(--uiInput);
  color: var(--textDiscreet);
  margin: 0;
  pointer-events: all;
  cursor: pointer;

  input[type='file'] {
    display: none;
  }
  @media (hover: hover) {
    &:hover {
      background: transparent;
      border-color: var(--uiSecondary);
      color: var(--uiSecondary);
    }
  }

  &:active {
    background: transparent;
    border-color: var(--uiSecondary);
    color: var(--uiSecondary);
  }

  &:disabled {
    border-color: var(--textDisabled);
    color: var(--uiBase);
    opacity: 0.5;
  }
}

label.mini {
  padding: 0 10px;
  height: 34px;
}

@import "ui/styles/mixins";
.loader {
  margin: 0 0 2em;
  text-align: center;
  margin: 0 auto 0;
  display: inline-block;
  vertical-align: top;
}

.small {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;

  svg {
    height: 24px;
    width: 24px;
  }
}

.halfPage {
  min-height: calc(50vh);
  margin-bottom: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullPage {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--uiWhite);
  z-index: 1000;
}

.fullWidth {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--size-5);
  margin-bottom: var(--size-5);
}

.fadeLoader {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.fadeLoaderVisible {
  opacity: 1;
}

@import "ui/styles/mixins";
.logo {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-right: var(--outlineWidth) solid var(--uiOutline);

  @media (min-width: 900px) {
    padding-left: 0;
    padding-right: 30px;
  }

  // 1. Logo
  svg:nth-child(1) {
    display: none;
    @media (min-width: 900px) {
      display: block;
    }
  }
  // 1. Mark
  svg:nth-child(2) {
    display: block;
    @media (min-width: 900px) {
      display: none;
    }
  }
}

.mark {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 19px;
  border-right: var(--outlineWidth) solid var(--uiOutline);

  @media (min-width: 900px) {
    padding-left: 0;
    padding-right: 30px;
  }
  @media (min-width: 1180px) {
    padding-left: 0;
  }
}

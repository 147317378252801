// Typography
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.woff2') format('woff2'), url('/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-SemiBold.woff2') format('woff2'), url('/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: bold;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.woff2') format('woff2'), url('/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Libre Baskerville';
  src: url('/fonts/libre-baskerville-v9-latin-regular.woff2') format('woff2'),
    url('/fonts/libre-baskerville-v9-latin-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Libre Baskerville';
  src: url('/fonts/libre-baskerville-v9-latin-700.woff2') format('woff2'),
    url('/fonts/libre-baskerville-v9-latin-700.woff') format('woff');
  font-weight: 700;
  font-style: bold;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Libre Baskerville';
  src: url('/fonts/libre-baskerville-v9-latin-italic.woff2') format('woff2'),
    url('/fonts/libre-baskerville-v9-latin-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

.body {
  @include uiBase;
}

.body-large {
  @include displayParagraph;
}

.displayHeader {
  @include displayHeader;
}

.sectionHeader {
  @include sectionHeader;
}

.italic {
  @include italic;
}

.medium {
  @include medium;
}

.underline {
  @include underline;
}

.uiBase {
  @include uiBase;
}

.uiBaseFocus {
  @include uiBaseFocus;
}

.uiNav {
  @include uiNav;
}

.uiObjectTitleFocus {
  @include uiObjectTitleFocus;
}

.uiObjectTitle {
  @include uiObjectTitle;
}

.uiButton {
  @include uiButton;
}

.uiDiscreet {
  @include uiDiscreet;
}

.textAccent {
  color: var(--accentPrimary);
}

.textDiscreet {
  color: var(--textDiscreet);
}

.textBlack {
  color: var(--textBlack);
}

.displayHeader {
  @include displayHeader;
}

.displayParagraph {
  @include displayParagraph;
}

.italic {
  @include italic;
}

.center {
  @include center;
}

.left {
  @include left;
}

.twoColumnsText {
  @include min600 {
    column-count: 2;
    column-gap: 20px;
  }
}

.h2SansSerif {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 20px;

  @include min900 {
    font-size: 34px;
  }
}

@import "ui/styles/mixins";
.link {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: var(--outlineWidth) solid var(--uiOutline);
  color: var(--textDiscreet);
  cursor: pointer;
  margin: 8px -8px -4px; // To offset the dropdown styles, maybe this should be a dropdown extention
}

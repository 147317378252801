@import "ui/styles/mixins";
.button {
  padding: 0;
  background: transparent;
  color: var(--textPrimary);
  border: none;
  outline: none;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  display: inline-flex;
  align-items: center;

  > svg {
    margin-right: var(--size-1);
    margin-left: 0;
  }
  > svg:last-child {
    margin-right: 0;
    margin-left: var(--size-1);
  }
}

// Variants
.underline {
  color: var(--textPrimary);
  text-decoration: underline;
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimaryHover);
    }
    &:active {
      color: var(--accentPrimaryActive);
    }
  }
}

.bold {
  font-weight: 600;
  color: var(--textPrimary);
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimaryHover);
    }
    &:active {
      color: var(--accentPrimaryActive);
    }
  }
}

.basic {
  color: var(--textPrimary);
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimaryHover);
    }
    &:active {
      color: var(--accentPrimaryActive);
    }
  }
}

.faded {
  color: var(--textDiscreet);
  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
    }

    &:active {
      color: var(--textBlack);
    }
  }
}

.fadedUnderline {
  color: var(--textDiscreet);
  text-decoration: underline;
  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
    }

    &:active {
      color: var(--textBlack);
    }
  }
}

.accent {
  color: var(--accentPrimary);
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimaryHover);
    }

    &:active {
      color: var(--accentPrimaryActive);
    }
  }
}

.none {
  // nothing to see here
}

// Sizes
.mini {
  font-size: 0.9em;
}

.normal {
  font-size: 1em;
}

.inherit {
  font-size: inherit;
}

.iconOnly {
  margin: 0;
  padding: 0;
  svg {
    margin: 0;
    padding: 0;
  }
}

@import "ui/styles/mixins";
.container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--uiWhite);
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  z-index: var(--zIndexHeader);
  height: calc(55px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--size-5);
  padding-right: var(--size-5);

  @media (min-width: 600px) {
    display: none;
  }

  &.noBottomBorder {
    border-bottom: 1px transparent var(--outlineWidth);
  }
}

:global(.bodyUiBoxBackground) .container {
  background-color: var(--uiBoxBackground);
}

.buttonLeft {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 24px;
}

.buttonsRight {
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonSlot {
  display: flex;
  align-items: center;
  > * {
    display: inline-flex;
    margin-left: var(--size-4);
    flex: 0 0 auto;
    &:empty {
      margin: 0;
    }
  }
}

.title {
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pushdown {
  height: calc(55px + env(safe-area-inset-top));
  @media (min-width: 600px) {
    display: none;
  }
}

.supportLink {
  display: block;
  height: 24px;
}

@import "ui/styles/mixins";
.container {
  width: 100%;
  min-height: 48px;
  padding: var(--size-1) var(--size-2);
  display: flex;

  .inner {
    @include resetButton;
    text-align: left;
    align-items: center;
    cursor: pointer;
    border-radius: var(--size-1);
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-left: var(--size-2);
    padding-right: var(--size-2);
    user-select: none;
    transition: all 200ms ease-in-out;
    &.selected {
      background: var(--uiBoxBackground);

      @media (hover: hover) {
        &:hover {
          background: var(--uiHover);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        background: var(--uiHover);
      }
      &:focus {
        background: var(--uiHover);
      }
    }

    &:active {
      background: var(--uiBaseLight);
    }

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
      color: var(--textDiscreet);
    }

    .icon {
      margin-right: var(--size-2);
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
    }

    .noIconSpacer {
      padding-left: var(--size-2);
    }

    // Variants
    &.underline {
      color: var(--textPrimary);
      text-decoration: underline;
      @media (hover: hover) {
        &:hover {
          color: var(--accentPrimaryHover);
        }
        &:active {
          color: var(--accentPrimaryActive);
        }
      }
    }

    &.bold {
      font-weight: 600;
      color: var(--textPrimary);
      @media (hover: hover) {
        &:hover {
          color: var(--accentPrimaryHover);
        }
        &:active {
          color: var(--accentPrimaryActive);
        }
      }
    }

    &.basic {
      color: var(--textPrimary);
      @media (hover: hover) {
        &:hover {
          color: var(--accentPrimaryHover);
        }
        &:active {
          color: var(--accentPrimaryActive);
        }
      }
    }

    &.faded {
      color: var(--textDiscreet);
      @media (hover: hover) {
        &:hover {
          color: var(--textPrimary);
          background: transparent;
        }

        &:active {
          color: var(--textBlack);
        }
      }
    }

    &.fadedUnderline {
      color: var(--textDiscreet);
      text-decoration: underline;
      @media (hover: hover) {
        &:hover {
          color: var(--textPrimary);
        }

        &:active {
          color: var(--textBlack);
        }
      }
    }

    &.accent {
      color: var(--accentPrimary);
      @media (hover: hover) {
        &:hover {
          color: var(--accentPrimaryHover);
        }

        &:active {
          color: var(--accentPrimaryActive);
        }
      }
    }
  }
}

.title {
  @include limitTextLines(1);
  text-align: left;
}

@import "ui/styles/mixins";
.link {
  transition: border-bottom 300ms ease-in-out;
  transition: color 300ms ease-in-out;
  text-decoration: underline;
}

.accent {
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimary);
    }
  }
  &:active {
    color: var(--accentPrimaryActive);
  }
}

@import "ui/styles/mixins";
.checkbox,
label.checkbox {
  display: flex;
  margin: 0;
  align-items: center;

  .box {
    display: flex;
    width: 26px;
    height: 26px;
    border: var(--outlineWidth) solid var(--uiInput);
    border-radius: 2px;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    transition: border 200ms ease-in-out;

    svg {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
    }
  }

  .text {
    // 100% - checkbox size - text margin - border
    width: calc(100% - 26px - var(--size-2) - var(--outlineWidth) - var(--outlineWidth));
    display: inline;
    padding-left: var(--size-2);
    color: var(--textDiscreet);

    // Hack for safari flexbox with inter font
    // https://github.com/rsms/inter/issues/321
    letter-spacing: 0.000002px;
  }

  input {
    width: 0;
    position: absolute;
    left: -9999px;

    &:checked ~ .text {
      color: var(--textPrimary);
    }
  }

  input:checked + .box {
    background-color: var(--uiBaseLight);
  }
  input:checked + .box svg {
    opacity: 1;
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      .box {
        border-color: var(--uiBlack);
      }
    }
  }
}

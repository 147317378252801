@import "ui/styles/mixins";
.menu {
  position: fixed;
  z-index: var(--zIndexDropdown);
  background-color: var(--uiOverlayBackground);
  display: flex;
  flex-direction: column;
  color: var(--textPrimary);
  border-radius: 4px;
  will-change: filter;
  filter: drop-shadow(0px 0px 8px var(--shadowColor));
  cursor: default;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
  padding: 0;
  &[data-popper-placement^='top'] > .arrow {
    bottom: -14px;
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -10px;
  }
}

.padding {
  padding: var(--size-1) var(--size-2);
}

.arrow {
  height: 16px;
  width: 16px;
  &::before {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    background-color: var(--uiOverlayBackground);
    transform: rotate(45deg);
    position: relative;
    bottom: 5px;
    z-index: 0;
  }
}

.content {
  z-index: 1;
  min-width: 250px;
}

@import "ui/styles/mixins";
.container {
  position: relative;
}

.avatar {
  display: block;
  width: 40px;
  flex: 0 0 40px;
  height: 40px;
  background-color: var(--uiWhite);
  border-radius: var(--avatarRadius);
  object-fit: cover;
  user-select: none;
  color: transparent;
}

.link {
  position: relative;
  @media (hover: hover) {
    transition: filter 200ms linear;
    &:hover {
      filter: brightness(1.1);
    }
  }
}

@import "ui/styles/mixins";
.container {
  position: relative;
  width: 100%;
}

.expandForWarning {
  min-height: 92px;
}
.inner {
  filter: blur(0px);
  transition: filter 200ms ease-in-out;
}
.spoiler {
  filter: blur(8px);
  will-change: filter;
}

.spoilerWarning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  button {
    margin-top: 24px;
    width: max-content;
  }
}

@import "ui/styles/mixins";
.loggedIn {
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 0;
  cursor: pointer;

  @include min600 {
    height: 60px;
  }

  @include min900 {
    height: 80px;
    padding-left: var(--size-3);
  }

  @media (hover: hover) {
    &:hover .handle {
      color: var(--textPrimary);
    }
  }
}

.handle {
  transition: color 200ms linear;
  color: var(--textDiscreet);
  display: flex;
  justify-content: center;
}

.avatar {
  margin-left: var(--size-2);
  margin-right: var(--size-5);
  @media (min-width: 900px) {
    margin-right: 0;
  }
}

.active {
  color: var(--textPrimary);
}

@import "ui/styles/mixins";
.patronBadgeSmall {
  background: var(--accentPrimary);
  color: var(--textWhite);
  display: flex;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 2px;
  position: absolute;
  bottom: -5px;
  right: -10px;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    color: var(--textWhite);
    filter: brightness(1.2);
  }
}

@import "ui/styles/mixins";
.container {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--uiWhite);
  border-top: var(--outlineWidth) solid var(--uiOutline);
  z-index: var(--zIndexHeader);
  height: calc(55px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  align-items: center;
  span {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
  }

  @media (min-width: 600px) {
    display: none;
  }
  button,
  a {
    width: 45px;
  }
}

.inner {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--textSecondary);
  &.active {
    color: var(--textBlack);
  }
}

.mobileSearchButton {
  background: transparent;
  margin: 0;
  border: none;
  padding: 0;
}

.pushup {
  height: calc(55px + env(safe-area-inset-bottom));
  @media (min-width: 600px) {
    display: none;
  }
}

.unread {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--accentPrimary);
  }
}

@import "ui/styles/mixins";
.container {
  position: absolute;
  z-index: 1;
  top: 56px;
  height: calc(100vh - 56px);
  background: var(--uiWhite);
  width: 100%;
  @media (min-width: 600px) {
    left: -1px;
    border-radius: 0 0 5px 5px;
    border: var(--outlineWidth) solid var(--uiOutline);
    display: block;
    padding-bottom: 0;
    top: 59px;
    height: auto;
    max-height: calc(100vh - 60px);
    min-width: calc(100vw - 128px);
  }
  @media (min-width: 900px) {
    max-height: calc(100vh - 80px);
    top: 79px;
    min-width: calc(100vw - 70px - 160px);
  }
  @media (min-width: 1180px) {
    min-width: 100%;
    width: calc(100% + 2px);
  }
}

.inner {
  @include scrollingY;
}
